<template>
  <div>
    <el-form label-position="right">
      <title-and-description :field="field" />
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="template-sharing-button">
            <el-switch
              style="display: block"
              v-model="field.countryCodeSelector"
              active-color="#13ce66"
              inactive-color="#ff4949"
              inactive-text="Custom"
              active-text="Standard"
              v-on:change="onUpdate"
            ></el-switch>
          </div>
        </el-col>
        <el-col :span="12">
           <div class="vue-phone-validator">
            <VuePhoneNumberInput
              class="vue-phone-validator"
              required
              :default-country-code="field.defaultCountry"
              v-model="field.phoneNo"
              @update="onUpdate"
            /> 
          </div> 
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30">
        <el-col :span="12" class="mt">
          <field-filled-by :field="field" />
        </el-col>
        <el-col :span="12">
          <is-field-required :field="field" />
        </el-col>
      </el-row>

      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <field-attributes :field="field" />
        </el-col>
      </el-row>
      <div class="mt-1"></div>
    </el-form>
  </div>
</template>

<script>
export default {
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    VuePhoneNumberInput: () => import("vue-phone-number-input"),
    FieldAttributes: () => import("./FieldAttributes"),
  },
  props: ["field"],
  data() {
    return {
      validations: [],
    };
  },
  methods: {
     onUpdate(payload) {
      if (this.field.countryCodeSelector == true) {
        this.standardCode = false;
        this.field.countryDisable = false;
        this.field.countryCallingCode = payload.countryCallingCode;
        this.field.defaultCountry = payload.countryCode;
      } else {
        this.field.defaultCountry = payload.countryCode;
        this.field.countryCallingCode = payload.countryCallingCode;
        this.field.countryDisable = true;
      }
    },
    openSettings() {
      this.$emit("settings");
    },
  },
};
</script>

<style lang="scss">
.mt {
  margin-top: 10px;
}
</style>
